import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import HomeLayout from '../layouts/HomeLayout';
import Partners from '../components/Partners';
import References from '../components/References';

const BaseTemplate = ({ page, children }) => {
  const allReferences = {
    index: [
      'Cyber Security Ventures article: <a target="_blank" href="https://cybersecurityventures.com/cybercrime-damages-6-trillion-by-2021/">Cybercrime Damages $6 Trillion By 2021</a>',
      'Accenture Security, 2019 The Cost of Cybercrime study (PDF): <a target="_blank" href="https://www.accenture.com/_acnmedia/PDF-96/Accenture-2019-Cost-of-Cybercrime-Study-Final.pdf">Accenture 2019 Cost of Cybercrime Study</a>',
      '<a target="_blank" href="https://www.cisco.com/c/en/us/products/security/security-reports.html">Cisco Cybersecurity Report Series</a>'
    ],
    organizations: [
      'Accenture\'s global study: <a target="_blank" href="https://www.accenture.com/_acnmedia/PDF-96/Accenture-2019-Cost-of-Cybercrime-Study-Final.pdf">Ninth Annual Cost of Cybercrime Study</a>',
      '<a target="_blank" href="https://go.forrester.com/blogs/justifying-your-investment-in-the-people-and-awareness-side-of-security/">Justify Your Investment In The People And Awareness Side of Security</a>',
      '<a target="_blank" href="https://www.gartner.com/en/newsroom/press-releases/2018-08-15-gartner-forecasts-worldwide-information-security-spending-to-exceed-124-billion-in-2019">Gartner Forecasts Worldwide Information Security Spending to Exceed $124 Billion in 2019</a>'
    ],
    individuals: [
      'Source: <a target="_blank" href="https://www.isc2.org/-/media/ISC2/Research/2020/Workforce-Study/ISC2ResearchDrivenWhitepaperFINAL.ashx?la=en&hash=2879EE167ACBA7100C330429C7EBC623BAF4E07B">(ISC)<span class="custom-uppertext">2</span> CYBERSECURITY WORKFORCE STUDY, 2020</a>',
      'Clearance Jobs, News article: <a target="_blank" href="https://news.clearancejobs.com/2018/04/24/cybersecurity-fastest-growing-job-huge-skills-gap/">Why Cybersecurity Is the Fastest Growing Job with a Huge Skills Gap</a>',
      'Monster article: <a target="_blank" href="https://www.monster.com/career-advice/article/future-of-cybersecurity-jobs">The Future of Cybersecurity Jobs</a>',
      'ISACA: <a target="_blank" href="https://cybersecurity.isaca.org/state-of-cybersecurity">State of Cybersecurity 2019</a>'
    ]
  };

  let refs = [];

  if (Object.prototype.hasOwnProperty.call(allReferences, page)) {
    refs = allReferences[page];
  }

  return (
    <HomeLayout>
      <div className="wef-content">{children}</div>
      <Partners />
      <References data={refs} />
    </HomeLayout>
  );
};

BaseTemplate.propTypes = {
  page: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default BaseTemplate;
