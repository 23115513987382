import React from "react";

const TrailCards = ({trailCards}) => {
    return (
        <div className="slds-grid slds-grid_align-center slds-wrap slds-grid_pull-padded-medium slds-grid_vertical-stretch">
            {
                trailCards.map((trail) => {
                    return (
                        <div className={`slds-p-around--medium slds-size--1-of-1 slds-medium-size--1-of-2 ${trailCards.length > 2 && 'slds-large-size--1-of-3'}`} >
                            <div style={{borderTopColor: trail.color, height:'100%'}} className="tds-content-tile slds-text-body_small tds-color_meteorite slds-is-relative">
                                <div className="tds-content-panel_body">
                                    <div className="slds-float_right slds-text-align_right">
                                        <div>
                                            <div className="slds-m-bottom_small">
                                                <span className="slds-badge slds-grid_vertical-align-center">{!!trail.points && `+${trail.points} points`}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <a href={trail.url} aria-hidden="true" tabIndex="-1" className="slds-is-relative slds-show" style={{width:'90px'}}>
                                            <img className={!trail.imageHasBackground && 'wef-icon-round-border'} src={trail.image} alt="" width="90"/>
                                        </a>
                                    </div>
                                    <div className="slds-m-vertical_small" style={{overflow:'hidden', height:'7.5rem'}}>
                                        <div>{trail.type}</div>
                                        <h3 className="tds-text-size_6 slds-hyphenate tds-text_bold slds-m-bottom_xx-small" style={{maxHeight:'2.875rem', overflow:'hidden', lineHeight:'1.3'}}>
                                            <a href={trail.url}>{trail.title}</a>
                                        </h3>
                                        <p style={{maxHeight:'4.125rem', textOverflow:'ellipsis'}}>
                                            {trail.description}
                                        </p>
                                    </div>
                                    <div className="slds-text-align_right">
                                        <span>{!!trail.duration && `~${trail.duration}`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}
export default TrailCards;
