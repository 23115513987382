import React from 'react';
import PropTypes from 'prop-types';

const References = ({ data }) => {

  const refSection = [];
  data.forEach((el, index) => {
    const key = index + 1;
    refSection.push(
      <li
        key={key}
        dangerouslySetInnerHTML={{ __html: `${key}. ${el}` }} />
    );
  });

  if(refSection.length > 0){
    return (
      //  the return() to put your default HTML into the DOM
      <div className="wef-section--references Bgc(gray)" style={{borderTop: '1px solid #dedede'}}>
        <div className="custom-container--x-large slds-container--center slds-p-vertical--xx-large">
          <div className="wef-section_title-small slds-p-bottom--large">References</div>
          <ul className="C(gray1) Fz(13) Lh(21)">
            {refSection}
          </ul>
        </div>
      </div>
    );

  }
  else{
    return (
      <div/>
    );
  }
  
};


export default References;
