import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Hero from '../components/Hero';
import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query HomeLayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
      }
    }
  }
`;

const HomeLayout = ({ children }) => {

  if (typeof window !== 'undefined') {
    const data = { actions: [] };
    // console.info('PostMessage removing #class because it is the index page (HomeLayout)');
    data.actions.push({ type: 'removeHashVar', value: 'class' });
    // window.parent.postMessage(data, '*');
  }

  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />

            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/Nexa/Nexa.css"/>
            <meta property="og:title" content={siteMetadata.title} />
            <script type="text/javascript" defer src="https://play.vidyard.com/embed/v4.js"></script>
          </Helmet>
          <div id="wef-site" className="wef-site">
            {children}
          </div>
        </>
      )}
    />
  );
};

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default HomeLayout;
