import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import BaseTemplate from '../../templates/BaseTemplate';
import MenuContent from '../../components/MenuContent';
import SvgMap from '../../components/SvgMap';
import TrailCards from '../../components/TrailCards'

import jobsCreatedMap from '../../assets/images/jobs-created-map.png'; // Tell Webpack this JS file uses this image
import secureShield from '../../assets/images/secure-shield.png'; // Tell Webpack this JS file uses this image
import imgOvalBlue from '../../assets/images/oval-blue.png'; // Tell Webpack this JS file uses this image
import imgOvalGreen from '../../assets/images/oval-green.png'; // Tell Webpack this JS file uses this image

// <img alt="jobs created" src={jobsCreatedMap} />

const trailCardsQuery = graphql`
  query trailCards {
    allTrailCardsDataJson(filter: {appearsIn: {glob: "individuals"}}) {
      edges {
        node {
          duration
          description
          color
          image
          imageHasBackground
          points
          title
          type
          url
        }
      }
    }
  }
`;

const renderTrailCards = ({allTrailCardsDataJson}) => {
  return <TrailCards trailCards={allTrailCardsDataJson.edges.map(({node}) => node)}/>;
};

const IndividualsPage = () => (
  <BaseTemplate page="individuals">
    <div className="wef-hero-individuals custom-align--vertical-center">
      <div className="custom-container--x-large slds-container--center">
        <div className="slds-grid">
          <div className="slds-size--12-of-12">
            <div className="wef-hero_title">
              Learn new skills to get a top job in cybersecurity
            </div>
            {
              // <div className="wef-hero_description">
              //   A fun way to learn about cybersecurity and acquire new skills.
              // </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div className="wef-content--individuals wef-individuals">
      <div className="wef-section Bgc(blue-dark)">
        <div className="wef-section_title slds-container--center C(white) Maw(656)">
        CYBERSECURITY WORKFORCE GAP IS at 3.1 MILLION<span className="custom-uppertext-million">1</span>
        </div>
        <div className="wef-map-grid custom-container--x-large slds-container--center">
          <div className="slds-grid slds-grid--vertical-stretch slds-wrap slds-grid_pull-padded-medium">
            <div className="slds-size--1-of-1 slds-large-size--3-of-12 slds-p-horizontal--medium custom-align--vertical-center">
              <div className="custom-small-container--center">
                <div className="slds-card tds-card wef-card-type-3 slds-align_absolute-center slds-p-horizontal--medium">
                  <div className="slds-p-vertical--large">
                    <div className="slds-text-align--center">
                      <div className="Fz(48) Lh(62) Fw(b)">#1</div>
                      <div className="Fz(14)">
                        Fastest growing<br/>
                        job market
                        <span className="custom-uppertext">2</span>
                      </div>
                    </div>
                    <div className="slds-text-align--center slds-m-top--x-large">
                      <div className="Fz(48) Lh(62) Fw(b)">37%</div>
                      <div className="Fz(14)">
                        YOY job growth
                        <span className="custom-uppertext">3</span>
                      </div>
                    </div>
                    <div className="slds-text-align--center slds-m-top--x-large">
                      <div className="Fz(48) Lh(62) Fw(b)">59%</div>
                      <div className="Fz(14)">
                      Organizations that have unfilled cybersecurity positions
                        <span className="custom-uppertext">4</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="slds-size--1-of-1 slds-large-size--9-of-12 slds-p-horizontal--medium">
              <SvgMap />
            </div>
          </div>
        </div>
      </div>

      <div className="wef-section">
        <div className="custom-container--x-large slds-container--center">
          <div className="slds-grid slds-grid--vertical-stretch slds-wrap slds-grid_pull-padded-medium">
            <div className="slds-size--1-of-1 slds-large-size--4-of-12 slds-p-horizontal--medium slds-text-align--right">
              <img className="wef-individuals--shield-image" alt="Secure Shield" src={secureShield} />
            </div>
            <div className="slds-size--1-of-1 slds-large-size--8-of-12 slds-p-horizontal--medium">
              <div className="wef-section_title slds-container--center Maw(560)">
                Cybersecurity skills lead to top jobs
              </div>
            </div>
          </div>

          <MenuContent />
        </div>
      </div>

      <div className="wef-section Bgc(gray)">
        <div className="wef-section_title slds-container--center">
          Start your learning journey
        </div>
        <div className="custom-container--x-large slds-container--center slds-m-top--medium">
          <StaticQuery query={trailCardsQuery} render={renderTrailCards}/>
        </div>
      </div>
    </div>
  </BaseTemplate>
);
export default IndividualsPage;
