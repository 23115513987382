import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

class MenuContent extends React.Component {
  constructor(props) {
    super(props);

    this.init = this.init.bind(this);
    this.updateContentBasedOnHash = this.updateContentBasedOnHash.bind(this);
    this.changeContent = this.changeContent.bind(this);
    this.getMenuLiItems = this.getMenuLiItems.bind(this);
    this.getContent = this.getContent.bind(this);

    this.state = {
      menuItems: [],
      menuSelectedItem: '',
      content: ''
    };
  }

  //  This NATIVE method is only called one time, which is before the initial render.
  componentWillMount() {
    // this.init();
  }

  componentDidMount() {
    this.updateContentBasedOnHash();
  }

  init = () => {
    const menuItems = [];
    let content = '';
    const { menuSelectedItem } = this.state;
    let selectedItemId = menuSelectedItem;
    let hash = '';

    this.props.roles.forEach((item, i) => {
      const index = i + 1;
      const menuItem = {
        'index': index,
        'text': item.title
      };
      const id = item.title.replace(/\s/g, '-').toLowerCase();
      if ( (hash !== '' && id === hash) || (hash === '' && id === menuSelectedItem)) {
        selectedItemId = id;
        content = {
          'index': index,
          'title': item.title,
          'subtitle': item.subtitle,
          'description': item.description,
          'listTitle': item.listTitle
        };
      }

      menuItems.push(menuItem);
    });

    this.setState({
      menuItems: menuItems,
      menuSelectedItem: selectedItemId,
      content: content
    });
  };

  updateContentBasedOnHash = () => {
    const menuItems = [];
    let content = '';
    const { menuSelectedItem } = this.state;
    let selectedItemId = menuSelectedItem;
    let hash = '';


    if (window !== undefined) {
      if(window.location.hash) {
        hash = window.location.hash.replace('#', '');
      }
    }

    this.props.roles.forEach((item, i) => {
      const index = i + 1;
      const menuItem = {
        'index': index,
        'text': item.title
      };
      const id = item.title.replace(/\s/g, '-').toLowerCase();
      if ( (hash !== '' && id === hash) || (hash === '' && i === 0)) {
        selectedItemId = id;
        content = {
          'index': index,
          'title': item.title,
          'subtitle': item.subtitle,
          'description': item.description,
          'listTitle': item.listTitle
        };
      }

      menuItems.push(menuItem);
    });

    this.setState({
      menuItems: menuItems,
      menuSelectedItem: selectedItemId,
      content: content
    });
  };

  changeContent = e => {
    e.preventDefault();
    e.stopPropagation();

    const { target } = e;
    const { itemId } = target.dataset;

    if ('pushState' in history) {
      //history.pushState(null, null, `#${itemId}`);
      history.replaceState(null, null, `#${itemId}`);
    } else {
      location.hash = itemId;
    }

    let content = '';

    this.props.roles.forEach((item, i) => {
      const index = i + 1;
      const id = item.title.replace(/\s/g, '-').toLowerCase();
      if (id === itemId) {
        content = {
          'index': index,
          'title': item.title,
          'subtitle': item.subtitle,
          'description': item.description,
          'listTitle': item.listTitle
        }
      }
    });

    this.setState({
      menuSelectedItem: itemId,
      content: content
    });
  };

  getMenuLiItems = () => {
    const menuLiItems = [];
    this.props.roles.forEach((item, i) => {
      const index = i + 1;
      const id = item.title.replace(/\s/g, '-').toLowerCase();
      const selectedClass =
        this.state.menuSelectedItem === '' || id === this.state.menuSelectedItem ?
          '' :
          'wef-btn--unselected';

      menuLiItems.push(
        <li key={index}>
          <button
            onClick={this.changeContent}
            data-item-id={id}
            type="button"
            className={`slds-button slds-button_neutral slds-m-bottom--xx-small ${selectedClass}`}
          >
            {item.title}
          </button>
        </li>
      );
    });

    return menuLiItems;
  };

  getContent = () => {
    const contentSection = [];

    this.props.roles.forEach((item) => {
      const { title, subtitle, description, listTitle } = item;
      const id = title.replace(/\s/g, '-').toLowerCase();
      contentSection.push(
        <div key={`anchor-${title}`} id={id}>
          <div></div>
        </div>
      );
    });

    if (this.state.content !== '') {
      const { title, subtitle, description, listTitle, slug } = this.state.content;
      const id = title.replace(/\s/g, '-').toLowerCase();
      const readMoreBtn = [];
      const defaultSlug = title.toLowerCase().replace(/ /g, '-');
      const urlTo = slug != null && slug != '' ? slug : `/cybersecurity/${defaultSlug}`;
      readMoreBtn.push(
        <div key={urlTo} class="slds-container--center slds-align_absolute-center">
          <a
          class="slds-button slds-button_brand tds-button_brand"
          href={urlTo}
          >
            Learn More
          </a>
        </div>
      )

      contentSection.push(
        <div key={`content-${title}`}>
          <div className="Fz(24) Lh(27) Fw(b) C(gray)">
            {title}
          </div>
          <div className="C(gray4) slds-m-top--x-small">
            {subtitle}
          </div>
          <div className="C(gray) slds-m-top--medium Maw(720)">
            {description}
          </div>
          <div className="C(gray) slds-m-top--medium Maw(720)">
            {listTitle}
          </div>
          {readMoreBtn}
        </div>
      );
    }

    return contentSection;
  };

  render() {
    const menuLiItems = this.getMenuLiItems();
    const contentSection = this.getContent();

    return (
      <div className="slds-grid slds-grid--vertical-stretch slds-wrap slds-grid_pull-padded-medium">
        <div className="slds-size--1-of-1 slds-medium-size--4-of-12 slds-p-horizontal--medium slds-text-align--center">
          <div className="wef-menu-content slds-p-top--large">
            <ul className="wef-menu-content-list">
              {menuLiItems}
            </ul>
          </div>
        </div>
        <div className="slds-size--1-of-1 slds-medium-size--8-of-12 slds-p-horizontal--medium custom-mobile-p-top--large">
          {contentSection}
        </div>
      </div>
    );
  }
}

MenuContent.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const individualsRoleData = graphql`
  query individualsRoleData {
    allIndividualsRoleDataJson{
      edges{
        node{
            title
            subtitle
            description
            listTitle
            slug
        }
      }
    }
  }
`;


const MenuContentQuery = () => (
  <StaticQuery
    query={individualsRoleData}
    render={ data => {
      let roles = [];
      data.allIndividualsRoleDataJson.edges.forEach((edge) => {
        const role = edge.node;
        if( role !== null ){
          roles.push(role);
        }
      });

      return (
        <MenuContent roles={roles}/>
      )
    }}
  />
);

export default MenuContentQuery;
